import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { motion, useInView } from 'framer-motion';
import { fadeIn } from "../../../utils/motion-framer";
export default function OurClient() {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
      <motion.section
        variants={fadeIn('up', 1)}
        initial="hidden"
        animate="show"
        whileInView="show"
        viewport={{ once: false, amount: 0.7 }}
        className="w-full mb-12 md:mb-12 lg:mb-16 rounded-md"
        > 
            <div className="px-2 md:px-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-2xl text-center mb-8">
                  Problemy, które rozwiązujemy:
              </h2>
              <div className="max-w-3xl mx-auto">
                  {/* <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto mb-12">
                      Nasza aplikacja jest stworzona dla właścicieli obiektów, którzy:
                  </p> */}
                  <ul className="space-y-4 mb-8 mx-auto max-w-lg text-left">
                      <li className="flex items-center mx-auto max-w-md"> {/* Center block with max width */}
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Masz dość ciągłego odpowiadania na te same pytania od gości?</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-md">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Brak czasu na szybką reakcję sprawia, że tracisz potencjalne rezerwacje?</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-md">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Goście często pytają o rzeczy, które są już opisane na stronie?</span>
                      </li>
                  </ul>
                  
                    <p className="text-xl mb-12 text-center text-gray-400 mx-auto">
                      Mamy na to rozwiązanie!
                  </p>
              </div>
          </div>
      </motion.section>
  )
}
