import React from 'react'

import { CiClock2 } from "react-icons/ci";
import { FaGlobeEurope, FaRegStar } from "react-icons/fa";
import { GrLineChart } from "react-icons/gr";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { MdOutlineDashboardCustomize, MdOutlineSecurity, MdOutlineQueryStats, MdAutoStories, MdOutlineSettingsSuggest, MdQuestionMark } from "react-icons/md";
import { GiHouse } from "react-icons/gi";
import { motion } from "framer-motion";
import { fadeIn } from "../../../utils/motion-framer";
import Benefit from './Benefit.jsx';


export default function HowItWorks() {
  return (
      <motion.section
          variants={fadeIn('up', 1)}
          initial="hidden"
          animate="show"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
            id="benefits" className="w-full pt-12 pb-12 md:pb-24 lg:pb-24">
          <div className="container">
              <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-center mb-8">Jak działa nasz wirtualny rezydent?</h2>
              <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto mb-12">
                  Wyobraź sobie, że masz asystenta, który nigdy nie śpi i zawsze ma odpowiedź na pytania Twoich gości lub zarządza rezerwacjami. Nasz wirtualny rezydent to inteligentny chatbot, który przejmuje komunikację z gośćmi, zapewniając im szybkie i precyzyjne odpowiedzi na każdym etapie – od zapytania o rezerwację po pomoc w trakcie pobytu.
              </p>
              <div className="grid gap-10 lg:grid-cols-2">
                  <Benefit>
                    <div className="flex justify-center mb-4">
                        <MdQuestionMark className="h-20 w-20 mb-4 text-yellow-500" />
                    </div>
                    <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                        Automatyczna obsługa zapytań
                    </p>
                    <p className="text-sm text-neutral-600 dark:text-neutral-400">
                        Chatbot odpowiada na pytania o dostępność, ceny, udogodnienia, atrakcje w okolicy i inne najczęściej zadawane pytania.                     
                    </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          
                          <CiClock2 className="h-20 w-20 text-blue-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Dostępność 24/7
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Goście mogą otrzymać odpowiedź natychmiast – niezależnie od pory dnia.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <GiHouse className="h-20 w-20 mb-4 text-red-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Wsparcie podczas pobytu
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Informacje o użytkowaniu sprzętu, takich jak kominek czy sauna, oraz wskazówki dotyczące lokalnych atrakcji są zawsze pod ręką.
                      </p>
                  </Benefit>
                  <Benefit>
                        <div className="flex justify-center mb-4">
                            <FaGlobeEurope className="h-20 w-20 mb-4 text-indigo-500" />
                        </div>
                        <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                            Obsługa międzynarodowa – bez barier językowych
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-400">
                            Nasz wirtualny rezydent obsługuje wiele języków, co oznacza, że goście z całego świata mogą bezproblemowo komunikować się z Twoim obiektem.
                        </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdOutlineSettingsSuggest className="h-20 w-20 mb-4 text-violet-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Prosta konfiguracja
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Wystarczy kilka minut, by uruchomić chatbot na Twojej stronie lub w mediach społecznościowych.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdOutlineDashboardCustomize className="h-20 w-20 mb-4 text-orange-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Pełna personalizacja pod Twoją markę
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Każdy domek letniskowy ma swój charakter, a nasza aplikacja pozwala na pełną personalizację – od nazw, przez komunikaty, po wizualny styl. Dzięki temu Twój wirtualny rezydent idealnie dopasuje się do estetyki i wartości Twojego obiektu, wzmacniając Twoją markę.
                      </p>
                  </Benefit>

              </div>
          </div>
      </motion.section>
    )
}


