import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { motion, useInView } from 'framer-motion';
import { fadeIn } from "../../../utils/motion-framer";
export default function Features() {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
      <motion.section
        variants={fadeIn('up', 1)}
        initial="hidden"
        animate="show"
        whileInView="show"
        viewport={{ once: false, amount: 0.7 }}
        className="w-full mb-12 md:mb-12 lg:mb-16 rounded-md"
        > 
          <div className="px-2 md:px-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-2xl text-center mb-8">
                  Najważniejsze korzyści
              </h2>
              <div className="max-w-3xl mx-auto">
                  {/* <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto mb-12">
                      Dzięki naszemu rozwiązaniu zyskujesz więcej czasu i zadowolonych gości, a jednocześnie eliminujesz stres związany z ciągłym odpisywaniem na wiadomości. Chatbot nie tylko automatyzuje komunikację, ale też pomaga budować pozytywne doświadczenie gości, które przekłada się na lepsze opinie i więcej rezerwacji.
                  </p> */}
                  <ul className="space-y-4 mb-8 mx-auto max-w-xl text-left">
                      <li className="flex items-center mx-auto max-w-xl"> {/* Center block with max width */}
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Więcej rezerwacji: Chatbot szybko odpowiada na pytania, zanim gość zdecyduje się poszukać innej oferty.</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-xl">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Oszczędność czasu: Właściciele mogą skupić się na prowadzeniu biznesu, a nie na ciągłym odpisywaniu na wiadomości.</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-xl">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Zadowoleni goście: Szybkie odpowiedzi i profesjonalna obsługa budują pozytywne doświadczenie klientów.</span>
                      </li>
                  </ul>
              </div>
          </div>
      </motion.section>
  )
}
