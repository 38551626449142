import React, { useState } from 'react';
import axios from 'axios';
import { motion, useInView } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    email: yup
        .string()
        .email('Email jest nieprawidłowy.')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email jest nieprawidłowy.')
        .required('Email jest wymagany')
});

const WaitingListForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    const { register, handleSubmit, formState: { errors, touchedFields }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
            const formattedData = {
                email: data.email
            };
            const response = await axios.post('/waiting_list', { waiting_list_entry: formattedData }, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            setSubmitMessage('Dziękujemy za dołączenie do naszej listy oczekujących!');
            setIsSuccess(true);
            reset();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setSubmitMessage('Wystąpił błąd: ' + error.response.data.errors.join(', '));
            } else {
                setSubmitMessage('Wystąpił błąd. Proszę spróbować ponownie.');
            }
            setIsSuccess(false);
            console.error('Błąd przesyłania:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const inputClass = "w-full p-2 border-2 border-indigo-600 rounded focus:outline-none transition-all duration-300 dark:bg-neutral-800";
    const labelClass = "block text-sm font-medium text-white-400 mb-1";
    const errorClass = "text-red-500 text-xs mt-1";

    return (
        <motion.section
            id="contact"
            ref={ref}
            className="w-full py-12 md:py-24 lg:py-24 
            rounded-md bg-gradient-to-b from-neutral-800 via-neutral-850 to-neutral-900"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
        >
            <div className="container px-4 md:px-6">
                <div className="space-y-8 mb-12">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-2xl text-center mb-8">
                        Zainteresowany? Sprawdź to sam!
                    </h2>
                    <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto">
                        Zapisz się na listę oczekujących i jako pierwszy przetestuj naszego wirtualnego rezydenta dla swojego obiektu!
                    </p>
                </div>

                {isSuccess && submitMessage && (
                    <motion.div initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="border border-green-400 text-green-500 px-4 py-3 mb-4 rounded relative max-w-md mx-auto" role="alert">
                        <strong className="font-bold">Sukces! </strong>
                        <span className="block sm:inline">{submitMessage}</span>
                    </motion.div>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 max-w-md mx-auto">
                    {/* <div>
                        <label htmlFor="fullName" className={labelClass}>Imię i nazwisko</label>
                        <motion.div
                         whileHover={{ scale: 1.01,  }}
                            onHoverStart={() => setIsHovered(true)} // Set hover state
                            onHoverEnd={() => setIsHovered(false)} // Reset hover state
                            className={`relative transition-all duration-300 ${isHovered ? 'border-red-500' : 'border-indigo-600'}`} // Conditional class for border color
                        >
                            <input
                                type="text"
                                id="fullName"
                                {...register('fullName')}
                                className={inputClass}
                            />
                            <motion.div
                                className="focus:ring-transparent absolute inset-0 rounded pointer-events-none"
                                initial={false}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                        {touchedFields.fullName && errors.fullName && <p className={errorClass}>{errors.fullName.message}</p>}
                    </div> */}

                    <div>
                        <label htmlFor="email" className={labelClass}>Email</label>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            className="relative"
                        >
                            <input
                                type="email"
                                id="email"
                                {...register('email')}
                                className={inputClass}
                            />
                            <motion.div
                                className="absolute inset-0 rounded pointer-events-none"
                                initial={false}
                                whileHover={{
                                    boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)",
                                }}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                        {touchedFields.email && errors.email && <p className={errorClass}>{errors.email.message}</p>}
                    </div>

                    {/* <div>
                        <label htmlFor="hotelName" className={labelClass}>Nazwa obiektu</label>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            className="relative"
                        >
                            <input
                                type="text"
                                id="hotelName"
                                {...register('hotelName')}
                                className={inputClass}
                            />
                            <motion.div
                                className="absolute inset-0 rounded pointer-events-none"
                                initial={false}
                                whileHover={{
                                    boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)",
                                }}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                        {touchedFields.hotelName && errors.hotelName && <p className={errorClass}>{errors.hotelName.message}</p>}
                    </div> */}

                    {/* <div>
                        <label htmlFor="message" className={labelClass}>Wiadomość</label>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            className="relative"
                        >
                            <textarea
                                id="message"
                                {...register('message')}
                                className={`${inputClass} h-32 resize-none`}
                            />
                            <motion.div
                                className="absolute inset-0 rounded pointer-events-none"
                                initial={false}
                                whileHover={{
                                    boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)",
                                }}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                        {touchedFields.message && errors.message && <p className={errorClass}>{errors.message.message}</p>}
                    </div> */}

                    <button
                        data-umami-event="cottage-waiting-list"
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full bg-indigo-600 text-white p-2 rounded hover:bg-indigo-700 transition-colors duration-300 disabled:opacity-50"
                    >
                        {isSubmitting ? 'Wysyłanie...' : 'Dołącz do listy oczekujących'}
                    </button>
                </form>
            </div>
        </motion.section>
    );
};

export default WaitingListForm;